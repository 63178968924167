import { processResponse } from '@/plugins/helper'
import {
  ARTICLE_CONTENT_MODEL,
  ARTICLE_METADATA_MODEL
} from 'enums/responseModels/article-model'
import { ARTICLE_SLOT_MODEL } from 'enums/responseModels/article-slot-model'
import { applyFallback } from '@/utils/helpers/applyFallback'
import { generateFullArticleModel } from './helpers'
import { NEWS_TYPE } from 'enums/personal-area'
import { SOURCE } from 'enums/source'

const remapSettings = {
  URL: 'url'
}

function format(data) {
  return processResponse(data, remapSettings)
}

/**
 * Usage: Single Article page
 */
export function generateArticleData(content, metaData, ctx) {
  const processedContent = applyFallback(content, ARTICLE_CONTENT_MODEL)
  const processedMetaData = applyFallback(metaData, ARTICLE_METADATA_MODEL)

  const { Name, Slug, ParentName, ParentSlug } = processedMetaData.Category

  const model = {
    id: processedMetaData.Id,
    slug: processedMetaData.Slug,
    title: processedContent.Topic,
    categoryName: ParentName || Name,
    categorySlug: ParentSlug || Slug,
    subcategoryName: ParentName ? Name : null,
    subcategorySlug: ParentSlug ? Slug : null,
    tldr: processedContent.TLDR,
    rawBody: processedContent.Body,
    brief: processedContent.Brief,
    tableData: processedMetaData.TableData,
    publishedOn: processedMetaData.PublishedOn,
    modifiedOn: processedContent.ModifiedOn,
    isSaved: processedMetaData.IsSaved,
    isMigrated: processedMetaData.IsMigrated,
    isDisclaimerVisible: processedMetaData.IsDisclaimerVisible,
    featuredImage: format(processedContent.FeaturedImage),
    featuredImageLink: format(processedContent.FeaturedImageLink),
    featuredVideo: format(processedContent.FeaturedVideo),
    author: format(processedMetaData.Author),
    authors: format(processedMetaData.Authors),
    sponsorshipCompany: format(processedMetaData.SponsorshipCompany),
    bodyImages: format(processedMetaData.BodyImages),
    bodyVideos: format(processedMetaData.BodyVideos),
    sticker: format(processedMetaData.Sticker),
    automaticLink: format(processedMetaData.AutomaticLink),
    terms: format(processedMetaData.Terms),
    tags: format(processedMetaData.Tags)
  }

  return generateFullArticleModel(model, ctx)
}

/**
 * Usage: Single article page - related articles
 */
export function generateRelatedArticleSlotData(data, ctx) {
  const processedData = applyFallback(data, ARTICLE_SLOT_MODEL.RELATED)

  const { Name, Slug } = processedData.Category
  const { Name: ParentName, Slug: ParentSlug } =
    processedData.ParentCategory || {}

  const model = {
    id: processedData.Id,
    slug: processedData.Slug,
    title: processedData.Topic,
    categoryName: ParentName || Name,
    categorySlug: ParentSlug || Slug,
    subcategoryName: ParentName ? Name : null,
    subcategorySlug: ParentSlug ? Slug : null,
    publishedOn: processedData.PublishedOn,
    featuredImage: format(processedData.FeaturedImage),
    featuredVideo: format(processedData.FeaturedVideo)
  }

  return generateFullArticleModel(model, ctx)
}

/**
 * Usage: Article Slot on the following pages:
 * - All News
 * - Category page
 * - Subcategory page
 * - Term page
 * - Tag page
 * - Author page
 * - Search Results page
 *
 * Fallback model: ARTICLE_SLOT_MODEL.GENERAL
 */
export function generateArticleSlotData(data, ctx) {
  const processedData = applyFallback(data, ARTICLE_SLOT_MODEL.GENERAL)

  const { Name, Slug, ParentName, ParentSlug } = processedData.Category

  const model = {
    id: processedData.Id,
    slug: processedData.Slug,
    title: processedData.Topic,
    categoryName: ParentName || Name,
    categorySlug: ParentSlug || Slug,
    subcategoryName: ParentName ? Name : null,
    subcategorySlug: ParentSlug ? Slug : null,
    tldr: processedData.Tldr,
    publishedOn: processedData.PublishedOn,
    modifiedOn: processedData.ModifiedOn,
    lastModified: processedData.LastModified,
    isSaved: processedData.IsSaved,
    isMigrated: processedData.IsMigrated,
    isDisclaimerVisible: processedData.IsDisclaimerVisible,
    isPinned: processedData.IsPinned,
    author: format(processedData.Author),
    authors: format(processedData.Authors),
    sponsorshipCompany: format(processedData.SponsorshipCompany),
    sticker: format(processedData.Sticker),
    mainTerm: format(processedData.MainTerm),
    mainTag: format(processedData.MainTag)
  }

  return generateFullArticleModel(model, ctx)
}

/**
 * Usage: Article slot for FM articles on the Homepage
 *
 * Fallback model: ARTICLE_SLOT_MODEL.HOMEPAGE
 */
export function generateHomepageArticleSlotData(data, ctx) {
  const processedData = applyFallback(data, ARTICLE_SLOT_MODEL.HOMEPAGE)

  const { Name, Slug } = processedData.Category
  const { Name: ParentName, Slug: ParentSlug } =
    processedData.ParentCategory || {}

  const model = {
    slug: processedData.Slug,
    title: processedData.Topic,
    categoryName: ParentName || Name,
    categorySlug: ParentSlug || Slug,
    subcategoryName: ParentName ? Name : null,
    subcategorySlug: ParentSlug ? Slug : null,
    tldr: processedData.Tldr,
    publishedOn: processedData.PublishedOn,
    featuredImage: format(processedData.FeaturedImage),
    featuredVideo: format(processedData.FeaturedVideo),
    sticker: format(processedData.Sticker),
    tags: format(processedData.Tags)
  }

  return generateFullArticleModel(model, ctx)
}

function generateSavedFmArticleSlotData(data, ctx) {
  const processedData = applyFallback(
    data,
    ARTICLE_SLOT_MODEL.SAVED_FOR_LATER_FM_ARTICLE
  )

  const { Name, Slug, ParentName, ParentSlug } = processedData.Category

  return {
    id: processedData.Id,
    slug: processedData.Slug,
    title: processedData.Topic,
    categoryName: ParentName || Name,
    categorySlug: ParentSlug || Slug,
    subcategoryName: ParentName ? Name : null,
    subcategorySlug: ParentSlug ? Slug : null,
    tldr: processedData.Tldr,
    publishedOn: processedData.PublishedOn,
    modifiedOn: processedData.ModifiedOn,
    isMigrated: processedData.IsMigrated,
    source: SOURCE.FM,
    type: NEWS_TYPE.FM,
    author: format(processedData.Author),
    authors: format(processedData.Authors),
    sponsorshipCompany: format(processedData.SponsorshipCompany),
    mainTerm: format(processedData.MainTerm),
    mainTag: format(processedData.MainTag)
  }
}
function generateSavedFmDirArticleSlotData(data, ctx) {
  const processedData = applyFallback(
    data,
    ARTICLE_SLOT_MODEL.NOTIFICATION_FM_DIR_ARTICLE
  )

  return {
    id: processedData.Id,
    slug: processedData.Slug,
    title: processedData.Topic,
    publishedOn: processedData.PublishedOn,
    modifiedOn: processedData.ModifiedOn,
    source: SOURCE.FM,
    type: NEWS_TYPE.FM_DIR,
    author: null,
    company: format(processedData.Company)
  }
}

function generateSavedFlArticleSlotData(data, ctx) {
  const processedData = applyFallback(
    data,
    ARTICLE_SLOT_MODEL.SAVED_FOR_LATER_FL_ARTICLE
  )

  return {
    id: processedData.Id,
    slug: processedData.Slug,
    title: processedData.Title,
    categoryName: processedData.Category.Name,
    categorySlug: processedData.Category.Slug,
    tldr: processedData.Tldr,
    publishedOn: processedData.PublishedOn,
    modifiedOn: processedData.ModifiedOn,
    isMigrated: processedData.IsMigrated,
    isPinned: processedData.IsPinned,
    source: SOURCE.FL,
    type: NEWS_TYPE.FL,
    author: format(processedData.Author),
    sponsorshipCompany: format(processedData.SponsorshipCompany),
    featuredImage: format(processedData.FeaturedImage),
    featuredVideo: format(processedData.FeaturedVideo),
    thumbnailImage: format(processedData.ThumbnailImage),
    terms: format(processedData.Terms)
  }
}

/**
 * Usage: Personal Area - Saved for later
 */
export function generatePASlotData(data, ctx) {
  const handlerByArticleKey = {
    Article: generateSavedFmArticleSlotData,
    DirectoryArticle: generateSavedFmDirArticleSlotData
  }

  /**
   * FM Saved articles response contains different keys for FM and FM-DIR articles:
   * {
   *   Article,
   *   DirectoryArticle
   * }
   *
   * FL Saved articles response doesn't contain any keys. All data are located
   * in the root object.
   */
  const articleKey = Object.keys(handlerByArticleKey).find(key => !!data[key])

  const handler = articleKey
    ? handlerByArticleKey[articleKey]
    : generateSavedFlArticleSlotData
  const article = articleKey ? data[articleKey] : data

  const model = handler(article, ctx)

  return generateFullArticleModel(model, ctx)
}

export function generatePANotificationFmArticleSlotData(data, ctx) {
  const processedData = applyFallback(
    data,
    ARTICLE_SLOT_MODEL.NOTIFICATION_FM_ARTICLE
  )

  const { Name, Slug, ParentName, ParentSlug } = processedData.Category

  return {
    id: processedData.Id,
    slug: processedData.Slug,
    title: processedData.Topic,
    categoryName: ParentName || Name,
    categorySlug: ParentSlug || Slug,
    subcategoryName: ParentName ? Name : null,
    subcategorySlug: ParentSlug ? Slug : null,
    tldr: processedData.Tldr,
    publishedOn: processedData.PublishedOn,
    modifiedOn: processedData.ModifiedOn,
    isMigrated: processedData.IsMigrated,
    source: SOURCE.FM,
    type: NEWS_TYPE.FM,
    author: format(processedData.Author),
    authors: format(processedData.Authors),
    sponsorshipCompany: format(processedData.SponsorshipCompany),
    mainTerm: format(processedData.MainTerm),
    mainTag: format(processedData.MainTag)
  }
}
export function generatePANotificationFmDirArticleSlotData(data, ctx) {
  const processedData = applyFallback(
    data,
    ARTICLE_SLOT_MODEL.NOTIFICATION_FM_DIR_ARTICLE
  )

  return {
    id: processedData.Id,
    slug: processedData.Slug,
    fmArticleUrl: processedData.FmArticleUrl,
    title: processedData.Topic,
    publishedOn: processedData.PublishedOn,
    modifiedOn: processedData.ModifiedOn,
    isMigrated: false,
    source: SOURCE.FM,
    type: NEWS_TYPE.FM_DIR,
    author: null,
    company: format(processedData.Company)
  }
}
export function generatePANotificationFlArticleSlotData(data, ctx) {
  const processedData = applyFallback(
    data,
    ARTICLE_SLOT_MODEL.NOTIFICATION_FL_ARTICLE
  )

  return {
    id: processedData.Id,
    slug: processedData.Slug,
    title: processedData.Title,
    categoryName: processedData.Category.Name,
    categorySlug: processedData.Category.Slug,
    tldr: processedData.Tldr,
    publishedOn: processedData.PublishedOn,
    modifiedOn: processedData.ModifiedOn,
    isMigrated: processedData.IsMigrated,
    source: SOURCE.FL,
    type: NEWS_TYPE.FL,
    author: format(processedData.Author),
    sponsorshipCompany: format(processedData.SponsorshipCompany),
    featuredImage: format(processedData.FeaturedImage),
    featuredVideo: format(processedData.FeaturedVideo),
    thumbnailImage: format(processedData.ThumbnailImage)
  }
}

/**
 * Usage: Personal Area:
 * - Notifications
 * - Followed Topics
 * - Followed Companies
 * - Followed Authors
 */
export function generatePANotificationSlotData(data, ctx) {
  const processedData = applyFallback(data, ARTICLE_SLOT_MODEL.NOTIFICATION)

  const handlerByArticleKey = {
    Article: generatePANotificationFmArticleSlotData,
    DirectoryArticle: generatePANotificationFmDirArticleSlotData,
    ForexLiveArticle: generatePANotificationFlArticleSlotData
  }

  const articleKey = Object.keys(handlerByArticleKey).find(
    key => !!processedData[key]
  )

  if (!articleKey) {
    throw new Error(`Unknown article type`)
  }

  const model = handlerByArticleKey[articleKey](processedData[articleKey], ctx)

  return generateFullArticleModel(
    {
      ...model,
      notificationId: processedData.Id,
      notificationAuthors: format(processedData.Authors),
      notificationTags: format(processedData.Tags),
      notificationCategories: format(processedData.Categories),
      notificationCompanies: format(processedData.Companies)
    },
    ctx
  )
}
