export const REFRESH_DATA_EVENT = {
  START_REFRESH_DATA: 'start-refresh-data',
  REFRESH_DATA: 'refresh-data',
  END_REFRESH_DATA: 'end-refresh-data'
}

export const METHOD_NAME = {
  HANDLE_START_REFRESH_DATA: 'handleStartRefreshData',
  HANDLE_END_REFRESH_DATA: 'handleEndRefreshData',
  HANDLE_REFRESH_DATA: 'handleRefreshData'
}
