import { REFRESH_DATA_EVENT } from 'enums/refresh-data'
import { checkIfFunction } from '@fmpedia/helpers'

function registerHandlersForEvent(handlers, event, bus) {
  const handler = ({ type, payload }) => {
    const handlerForType = handlers[type]
    if (checkIfFunction(handlerForType)) {
      handlerForType(payload)
    }
  }

  bus.$on(event, handler)

  return () => {
    bus.$off(event, handler)
  }
}

export default ({
  refreshHandlerByType,
  startRefreshHandlerByType,
  endRefreshHandlerByType
}) => {
  if (!process.client) return {}

  const generateRefreshHandlersByType = (ctx, handlerByType) => {
    return Object.entries(handlerByType).reduce((acc, [refreshType, value]) => {
      const handler = ctx[value]

      return { ...acc, [refreshType]: handler }
    }, {})
  }

  return {
    data() {
      return {
        refreshHandlerByType: refreshHandlerByType
          ? generateRefreshHandlersByType(this, refreshHandlerByType)
          : null,
        startRefreshHandlerByType: startRefreshHandlerByType
          ? generateRefreshHandlersByType(this, startRefreshHandlerByType)
          : null,
        endRefreshHandlerByType: endRefreshHandlerByType
          ? generateRefreshHandlersByType(this, endRefreshHandlerByType)
          : null,
        listenerRemovers: []
      }
    },
    methods: {
      $_refreshDataListeners_listenForPendingActionsUpdate() {
        if (this.startRefreshHandlerByType) {
          this.listenerRemovers.push(
            registerHandlersForEvent(
              this.startRefreshHandlerByType,
              REFRESH_DATA_EVENT.START_REFRESH_DATA,
              this.$bus
            )
          )
        }
        if (this.refreshHandlerByType) {
          this.listenerRemovers.push(
            registerHandlersForEvent(
              this.refreshHandlerByType,
              REFRESH_DATA_EVENT.REFRESH_DATA,
              this.$bus
            )
          )
        }
        if (this.endRefreshHandlerByType) {
          this.listenerRemovers.push(
            registerHandlersForEvent(
              this.endRefreshHandlerByType,
              REFRESH_DATA_EVENT.END_REFRESH_DATA,
              this.$bus
            )
          )
        }
      }
    },
    beforeMount() {
      this.$_refreshDataListeners_listenForPendingActionsUpdate()
    },
    beforeDestroy() {
      this.listenerRemovers.forEach(remover => {
        remover()
      })
    }
  }
}
