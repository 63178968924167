export const PENDING_ACTION = {
  SAVE_ARTICLE: 'SAVE_ARTICLE',
  SAVE_SEARCH_HISTORY: 'SAVE_SEARCH_HISTORY',
  FOLLOW_TAG: 'FOLLOW_TAG',
  FOLLOW_AUTHOR: 'FOLLOW_AUTHOR',
  FOLLOW_COMPANY: 'FOLLOW_COMPANY',
  ADD_COMPANY: 'ADD_COMPANY',
  BECOME_AN_AUTHOR: 'BECOME_AN_AUTHOR',
  REDIRECT_BACK_TO_PROFILE_URL: 'REDIRECT_BACK_TO_PROFILE_URL',
  OPEN_ARTICLE_NEWSLETTER_WIDGET: 'OPEN_ARTICLE_NEWSLETTER_WIDGET'
}

export const METHOD_NAME = {
  HANDLE_START_PENDING_ACTION: 'handleStartPendingAction',
  HANDLE_END_PENDING_ACTION: 'handleEndPendingAction',
  UPDATE_IS_SAVED_VALUE: 'updateIsSavedValue',
  UPDATE_IS_FOLLOWED_TAG_VALUE: 'updateIsFollowedTagValue',
  UPDATE_IS_FOLLOWED_AUTHOR_VALUE: 'updateIsFollowedAuthorValue'
}
