import { ROUTE_NAME } from 'enums/routes'
import { SOURCE } from 'enums/source'

export const NEWS_TYPE = {
  FM: 'FM',
  FM_DIR: 'FMDirectory',
  FL: 'FL'
}

export const NOTIFICATION_TYPE = {
  FOLLOWED_TAGS: 'FollowedTags',
  FOLLOWED_COMPANIES: 'FollowedCompanies',
  FOLLOWED_AUTHORS: 'FollowedAuthors'
}

export const FOLLOWED_ITEM = {
  TAGS: 'notificationTags',
  CATEGORIES: 'notificationCategories',
  AUTHORS: 'notificationAuthors',
  COMPANIES: 'notificationCompanies'
}

export const FOLLOWED_ITEM_METHOD_NAME = {
  UPDATE_IS_FOLLOWED_CATEGORY_VALUE: 'UPDATE_IS_FLLOWED_CATEGORY_VALUE'
}

export const ANNOUNCEMENT_TYPE = {
  EVENTS: 'Events',
  NEWS: 'News',
  PROMOTIONAL: 'Promotional',
  OTHER: 'Other'
}

export const TAB_NAME = {
  ALL: 'All',
  FM: 'Finance Magnates',
  FL: 'Forex Live'
}

export const QUERY_PARAM_NAME = 'source'
export const TAB_QUERY_PARAM_NAME = 'tab'

export const REFRESH_PERSONAL_AREA_DATA = 'REFRESH_PERSONAL_AREA_DATA'

export const QUERY_PARAM_VALUE = {
  [TAB_NAME.FM]: 'fm',
  [TAB_NAME.FL]: 'fl',
  [TAB_NAME.ALL]: 'all'
}

export const QUERY_PARAM_VALUE_BY_TAB_NAME = {
  [TAB_NAME.FM]: QUERY_PARAM_VALUE[TAB_NAME.FM],
  [TAB_NAME.FL]: QUERY_PARAM_VALUE[TAB_NAME.FL],
  [TAB_NAME.ALL]: QUERY_PARAM_VALUE[TAB_NAME.ALL]
}

export const TAB_NAME_BY_QUERY_PARAM_VALUE = {
  [QUERY_PARAM_VALUE[TAB_NAME.FM]]: TAB_NAME.FM,
  [QUERY_PARAM_VALUE[TAB_NAME.FL]]: TAB_NAME.FL,
  [QUERY_PARAM_VALUE[TAB_NAME.ALL]]: TAB_NAME.ALL
}

export const SOURCE_BY_TAB_NAME = {
  [TAB_NAME.ALL]: null,
  [TAB_NAME.FM]: SOURCE.FM,
  [TAB_NAME.FL]: SOURCE.FL
}

export const TAB_NAME_BY_SOURCE = {
  [SOURCE.FM]: TAB_NAME.FM,
  [SOURCE.FL]: TAB_NAME.FL
}

export const COMPANY_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  UNDER_MODERATION: 'UnderModeration',
  EXPIRING: 'Expiring',
  EXPIRED: 'Expired'
}

export const COMPANY_FEATURE_STATUS = {
  EXPIRING: 'Expiring',
  EXPIRED: 'Expired'
}

export const COMPANY_CONTENT_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  UNDER_MODERATION: 'UnderModeration'
}

export const COMPANY_CONTENT_STATUS_TITLE = {
  [COMPANY_CONTENT_STATUS.ACTIVE]: 'Active',
  [COMPANY_CONTENT_STATUS.INACTIVE]: 'Inactive',
  [COMPANY_CONTENT_STATUS.UNDER_MODERATION]: 'Under Moderation'
}

export const FILTER_TYPE = {
  PHONE: 'Phone',
  EMAIL: 'Email',
  URL: 'Url',
  TEXT_AREA: 'TextArea',
  SELECT: 'Select',
  MULTISELECT: 'MultiSelect',
  RADIO: 'Radio',
  CHECKBOX: 'Checkbox',
  BOOLEAN: 'Boolean'
}

export const MY_DIRECTORY_LISTINGS_CHILD_ROUTE_NAMES = [
  ROUTE_NAME.PERSONAL_PROFILE_COMPANY_NEWS,
  ROUTE_NAME.PERSONAL_PROFILE_COMPANY_VIDEOS,
  ROUTE_NAME.PERSONAL_PROFILE_ADD_COMPANY,
  ROUTE_NAME.PERSONAL_PROFILE_EDIT_COMPANY,
  ROUTE_NAME.PERSONAL_PROFILE_ADD_COMPANY_NEWS,
  ROUTE_NAME.PERSONAL_PROFILE_EDIT_COMPANY_NEWS
]

export const MY_CONTRIBUTIONS_CHILD_ROUTE_NAMES = [
  ROUTE_NAME.PERSONAL_PROFILE_ADD_POST,
  ROUTE_NAME.PERSONAL_PROFILE_EDIT_POST
]

export const INFO_NAVIGATION_SETTINGS = [
  {
    title: {
      desktop: 'General Information',
      mobile: 'General Information'
    },
    selector: '#general-information'
  },
  {
    title: {
      desktop: 'Social Media',
      mobile: 'Social Media'
    },
    selector: '#social-media'
  },
  {
    title: {
      desktop: 'Newsletters',
      mobile: 'Newsletters'
    },
    selector: '#newsletters'
  },
  {
    title: {
      desktop: 'My Products',
      mobile: 'My Products'
    },
    selector: '#my-products'
  }
]

export const SETTINGS_NAVIGATION_SETTINGS = [
  {
    title: {
      desktop: TAB_NAME.FM,
      mobile: TAB_NAME.FM
    }
  },
  {
    title: {
      desktop: TAB_NAME.FL,
      mobile: TAB_NAME.FL
    }
  }
]
