import {
  generateArticlePath,
  generateAuthorPath,
  generateFlArticlePath,
  generateFlAuthorPath,
  generateFlCompanyPath,
  generateFlUrl,
  generateFmDirArticlePath,
  generateFmDirCompanyUrl,
  generateFmDirUrl,
  generateFmUrl
} from '@/utils/helpers/url-generators'
import { getParsedHtmlWithReplacedMediaAttrs } from '@/utils/helpers/mediaProperties'
import { NEWS_TYPE } from 'enums/personal-area'
import { SOURCE } from 'enums/source'
import { USER_TYPE } from 'enums/users'
import defaultImage from '@/assets/img/default-avatar.jpg'

function getFmCanonicals(model, ctx) {
  const payloadForCanonicalPath = {
    path: model.path,
    slug: model.slug,
    categorySlug: model.categorySlug,
    subcategorySlug: model.subcategorySlug
  }
  const canonicalPath = generateArticlePath(payloadForCanonicalPath)
  const canonicalAmpPath = generateArticlePath(payloadForCanonicalPath, {
    amp: true
  })

  return {
    canonicalPath,
    canonicalAmpPath,
    canonicalUrl: generateFmUrl(canonicalPath, ctx),
    canonicalAmpUrl: generateFmUrl(canonicalAmpPath, ctx)
  }
}

function getFmDirCanonicals(model, ctx) {
  const payloadForCanonicalPath = {
    slug: model.slug,
    categorySlug: model.company.category.slug,
    companySlug: model.company.slug
  }
  const canonicalPath = generateFmDirArticlePath(payloadForCanonicalPath)

  return {
    canonicalPath,
    canonicalAmpPath: null,
    canonicalUrl: generateFmDirUrl(canonicalPath, ctx),
    canonicalAmpUrl: null
  }
}

function getFlCanonicals(model, ctx) {
  const payloadForCanonicalPath = {
    slug: model.slug,
    categorySlug: model.categorySlug,
    isMigrated: model.isMigrated
  }
  const canonicalPath = generateFlArticlePath(payloadForCanonicalPath)
  const canonicalAmpPath = generateFlArticlePath(payloadForCanonicalPath, {
    amp: true
  })

  return {
    canonicalPath,
    canonicalAmpPath,
    canonicalUrl: generateFlUrl(canonicalPath, ctx),
    canonicalAmpUrl: generateFlUrl(canonicalAmpPath, ctx)
  }
}

const getCanonicalsByType = {
  [NEWS_TYPE.FM]: getFmCanonicals,
  [NEWS_TYPE.FM_DIR]: getFmDirCanonicals,
  [NEWS_TYPE.FL]: getFlCanonicals
}

function generatePublisherFromAuthor(author) {
  return {
    ...author,
    link:
      author.role === USER_TYPE.SUBSCRIBER ? null : generateAuthorPath(author),
    imageUrl: author.photoUrl || defaultImage
  }
}

function getFmPublisherDetails(model, ctx) {
  const isPublisherAuthor = !!(
    model?.authors?.[0] &&
    (!model.sponsorshipCompany ||
      (model.isMigrated && !model.sponsorshipCompany.name))
  )
  const isPublisherCompany = !!model.sponsorshipCompany

  if (isPublisherAuthor) {
    const [mainAuthor, secondaryAuthor] = model.authors

    return [
      generatePublisherFromAuthor(mainAuthor),
      ...(secondaryAuthor ? [generatePublisherFromAuthor(secondaryAuthor)] : [])
    ]
  }

  if (isPublisherCompany) {
    const linkedCompany = model.sponsorshipCompany?.linkedCompany
    return [
      {
        name: model.sponsorshipCompany?.name,
        link: linkedCompany
          ? generateFmDirCompanyUrl(linkedCompany, ctx)
          : null,
        imageUrl: model.sponsorshipCompany?.logoUrl || null
      }
    ]
  }

  return []
}

function getFmDirPublisherDetails(model, ctx) {
  const publisherName = model.company?.name || ''
  const payload = {
    slug: model.company.slug,
    categorySlug: model.company.category.slug
  }
  const publisherLink = generateFmDirCompanyUrl(payload, ctx) || null

  return [{ name: publisherName, link: publisherLink }]
}

function getFlPublisherDetails(model, ctx) {
  const publisherName =
    model.sponsorshipCompany?.name || model.author?.name || ''
  let publisherLink = null
  if (model.sponsorshipCompany?.name) {
    publisherLink = model.sponsorshipCompany?.linkedCompany
      ? generateFlUrl(
          generateFlCompanyPath(model.sponsorshipCompany.linkedCompany),
          ctx
        )
      : null
  } else if (model.author?.slug) {
    publisherLink = generateFlUrl(generateFlAuthorPath(model.author?.slug), ctx)
  }

  return [{ name: publisherName, link: publisherLink }]
}

const getPublisherDetailsByType = {
  [NEWS_TYPE.FM]: getFmPublisherDetails,
  [NEWS_TYPE.FM_DIR]: getFmDirPublisherDetails,
  [NEWS_TYPE.FL]: getFlPublisherDetails
}

export function generateFullArticleModel(sourceModel, ctx) {
  const body =
    sourceModel.rawBody == null
      ? null
      : getParsedHtmlWithReplacedMediaAttrs(sourceModel.rawBody, {
          images: sourceModel.bodyImages,
          videos: sourceModel.bodyVideos,
          getDefaultImageAlt: () => sourceModel.title
        })

  const source = sourceModel.source || SOURCE.FM
  const type = sourceModel.type || NEWS_TYPE.FM

  const mainTerm =
    sourceModel.mainTerm ||
    sourceModel.terms?.find(({ isMain }) => isMain) ||
    null

  const mainTag =
    sourceModel.mainTag ||
    sourceModel.tags?.find(({ isMain }) => isMain) ||
    null

  const isFm = source === NEWS_TYPE.FM
  const isFmDir = source === NEWS_TYPE.FM
  const isFl = source === NEWS_TYPE.FL

  const getCanonicals = getCanonicalsByType[type]
  const {
    canonicalPath,
    canonicalAmpPath,
    canonicalUrl,
    canonicalAmpUrl
  } = getCanonicals
    ? getCanonicals(sourceModel, ctx)
    : {
        canonicalUrl: null,
        canonicalAmpUrl: null,
        canonicalPath: null,
        canonicalAmpPath: null
      }

  const getPublisherDetails = getPublisherDetailsByType[type]
  const publishers = getPublisherDetails
    ? getPublisherDetails(sourceModel, ctx)
    : []

  return {
    id: sourceModel.id || null,
    notificationId: sourceModel.notificationId || null,
    path: sourceModel.path || null,
    slug: sourceModel.slug || null,
    title: sourceModel.title || null,
    categoryName: sourceModel.categoryName || null,
    categorySlug: sourceModel.categorySlug || null,
    subcategoryName: sourceModel.subcategoryName || null,
    subcategorySlug: sourceModel.subcategorySlug || null,
    tldr: sourceModel.tldr || null,
    body,
    brief: sourceModel.brief || null,
    tableData: sourceModel.tableData || null,
    publishedOn: sourceModel.publishedOn || null,
    modifiedOn: sourceModel.modifiedOn || null,
    lastModified: sourceModel.lastModified || null,
    isSaved: sourceModel.isSaved,
    isMigrated: sourceModel.isMigrated,
    isDisclaimerVisible: sourceModel.isDisclaimerVisible,
    isPinned: sourceModel.isPinned,
    isNew: sourceModel.isNew,
    source,
    type: sourceModel.type || NEWS_TYPE.FM,
    featuredImage: sourceModel.featuredImage || null,
    featuredImageLink: sourceModel.featuredImageLink || null,
    featuredVideo: sourceModel.featuredVideo || null,
    thumbnailImage: sourceModel.thumbnailImage || null,
    author: sourceModel.author || null,
    authors: sourceModel.authors,
    sponsorshipCompany: sourceModel.sponsorshipCompany || null,
    company: sourceModel.company || null,
    bodyImages: sourceModel.bodyImages || [],
    bodyVideos: sourceModel.bodyVideos || [],
    sticker: sourceModel.sticker || null,
    automaticLink: sourceModel.automaticLink || null,
    terms: sourceModel.terms || [],
    mainTerm,
    tags: sourceModel.tags || [],
    mainTag,
    notificationAuthors: sourceModel.notificationAuthors || [],
    notificationTags: sourceModel.notificationTags || [],
    notificationCategories: sourceModel.notificationCategories || [],
    notificationCompanies: sourceModel.notificationCompanies || [],
    isFm,
    isFmDir,
    isFl,
    canonicalPath,
    canonicalAmpPath,
    canonicalUrl,
    canonicalAmpUrl,
    publishers
  }
}
