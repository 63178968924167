export default [
  {
    type: 'generalValidation',
    message: 'This field should not contain html tags'
  },
  {
    type: 'required',
    message: 'This field is required'
  },
  {
    type: 'email',
    message: 'This field should contain a valid email'
  },
  {
    type: 'youtubeUrl',
    message: 'This field should contain a valid Youtube video URL'
  },
  {
    type: 'validImagesInHtml',
    message:
      'This field contains invalid images, we have highlighted them in red for you. Please remove them and use the "Add image" button to properly insert images to the article'
  },
  {
    type: 'twitterCard',
    message: 'This field should contain a valid Twitter Card embed code'
  },
  {
    type: 'slug',
    message: 'This field should contain a valid slug'
  },
  {
    type: 'minValue',
    message: 'This field should be bigger than 0'
  },
  {
    type: 'maxValue',
    message: 'This field should be less than 100'
  },
  {
    type: 'url',
    message:
      'This field should contain a valid url. Example: "https://www.site.com/"'
  },
  {
    type: 'https',
    message: 'Do not put here an HTTP link. Only HTTPS links will be accepted'
  },
  {
    type: 'between',
    message: 'This field should be between 0.1 and 10 0000'
  },
  {
    type: 'numeric',
    message: 'This field should contain only numerics'
  },
  {
    type: 'phone',
    message:
      'Full phone number should start with "+" and contain from 6 to 16 digits'
  },
  {
    type: 'phoneCode',
    message: 'Phone Code should start with "+" and contain only digits (max 5)'
  },
  {
    type: 'invalidPin',
    message: 'Invalid pin code'
  },
  {
    type: 'invalidPhone',
    message: 'Invalid phone number'
  },
  {
    type: 'pinRequired',
    message: 'You have to pass pin code verification'
  },
  {
    type: 'mainTerm',
    message: 'The article should contain exactly one main term'
  },
  {
    type: 'secondaryTerm',
    message: 'The article should contain exactly one secondary term'
  },
  {
    type: 'textLength',
    message: 'The article text should be at least 1500 characters'
  },
  {
    type: 'articleTags',
    message: 'Please select tags (limit - min: 3 - max: 10)'
  },
  {
    type: 'sameAsPassword',
    message: 'Your password and confirmation password do not match'
  },
  {
    type: 'noSpaces',
    message: 'This field should not contain spaces'
  },
  {
    type: 'duplicateEmail',
    message: 'E-mail address is already in the list'
  },
  {
    type: 'acceptTerms',
    message: 'Please accept Terms of Service to proceed'
  },
  {
    type: 'repeatedPassword',
    message: 'New and re-typed passwords do not match'
  },
  {
    type: 'filesSize',
    message: 'File exceeds the maximum upload size'
  },
  {
    type: 'filesType',
    message: 'Unexpected file format'
  },
  {
    type: 'minWordCount'
  },
  {
    type: 'passwordNotSameAsEmail',
    message: 'Password must not be the same as your email'
  }
]
