import { mapActionsLazy } from '@/plugins/helper'
import { REFRESH_DATA_EVENT } from 'enums/refresh-data'

const SAVED_ENTITY = {
  ARTICLE: 'Article',
  DIRECTORY_NEWS: 'DirectoryNews',
  COMPANY: 'Company',
  TAG: 'Tag',
  CATEGORY: 'Category',
  AUTHOR: 'Author'
}

export default {
  computed: {
    $_refreshDataHandler_savedEntity() {
      return SAVED_ENTITY
    }
  },
  methods: {
    ...mapActionsLazy({
      requestSavedEntities: 'personal-area/requestSavedEntities',
      requestArticleContentBySlug: 'articles/requestArticleContentBySlug',
      requestAuthor: 'author/requestAuthor'
    }),
    $_refreshDataHandler_emitStartRefresh(type, payload) {
      this.$bus.$emit(REFRESH_DATA_EVENT.START_REFRESH_DATA, { type, payload })
    },
    $_refreshDataHandler_emitEndRefresh(type, payload) {
      this.$bus.$emit(REFRESH_DATA_EVENT.END_REFRESH_DATA, { type, payload })
    },
    $_refreshDataHandler_emitRefresh(type, payload) {
      this.$bus.$emit(REFRESH_DATA_EVENT.REFRESH_DATA, { type, payload })
    },
    $_refreshDataHandler_updateIsSavedValue(
      { id, value },
      articles = this.articles
    ) {
      return articles.map(article => ({
        ...article,
        ...(article.id === id ? { isSaved: value } : {})
      }))
    },
    $_refreshDataHandler_updateIsFollowedTagValue({
      id,
      value,
      articles = this.articles,
      tagListFieldName = 'tags',
      tagIdFieldName = 'id',
      isFollowedFieldName = 'isFollowed'
    }) {
      return articles.map(article => {
        const isMainTagUpdated =
          article.mainTag && article.mainTag[tagIdFieldName] === id

        return {
          ...article,
          ...(isMainTagUpdated
            ? {
                mainTag: {
                  ...article.mainTag,
                  isFollowed: value
                }
              }
            : {}),
          [tagListFieldName]: article[tagListFieldName].map(tag => ({
            ...tag,
            [isFollowedFieldName]:
              tag[tagIdFieldName] === id ? value : tag[isFollowedFieldName]
          }))
        }
      })
    },
    $_refreshDataHandler_updateIsFollowedAuthorValue({
      id,
      value,
      articles = this.articles,
      authorListFieldName = 'authors',
      authorIdFieldName = 'id',
      isFollowedFieldName = 'isFollowed'
    }) {
      return articles.map(article => {
        return {
          ...article,
          [authorListFieldName]: article[authorListFieldName].map(author => ({
            ...author,
            [isFollowedFieldName]:
              author[authorIdFieldName] === id
                ? value
                : author[isFollowedFieldName]
          }))
        }
      })
    },
    $_refreshDataHandler_updateIsFollowedCategoryValue({
      id,
      value,
      articles = this.articles
    }) {
      return articles.map(article => {
        return {
          ...article,
          notificationCategories: article.notificationCategories.map(
            category => ({
              ...category,
              isFollowed: category.id === id ? value : category.isFollowed
            })
          )
        }
      })
    },
    $_refreshDataHandler_resetActionIconValues(articles = this.articles) {
      return articles.map(article => ({
        ...article,
        isSaved: false,
        mainTag: { ...article.mainTag, isFollowed: false }
      }))
    },
    async $_refreshDataHandler_getSavedIdsOfEntities(arrayOfEntities) {
      const payload = { entityTypes: arrayOfEntities }
      try {
        const { SavedEntityIds } = await this.requestSavedEntities(payload)
        return SavedEntityIds
      } catch (err) {
        this.$errorHandler(err, this)
        return {}
      }
    },
    $_refreshDataHandler_updateArticleSavedEntities(
      { articleIds, tagIds },
      articles = this.articles
    ) {
      return articles.map(article => ({
        ...article,
        ...(articleIds && articleIds.includes(article.id)
          ? { isSaved: true }
          : {}),
        ...(tagIds && tagIds.includes(article.mainTag && article.mainTag.id)
          ? { mainTag: { ...article.mainTag, isFollowed: true } }
          : {})
      }))
    },
    async $_refreshDataHandler_getSingleArticleEntities(payload) {
      try {
        const {
          authors,
          isSaved,
          author: { isFollowed },
          tags,
          mainTag
        } = await this.requestArticleContentBySlug(payload)

        return {
          authorsIsFollowed: authors
            ? authors.map(({ isFollowed }) => isFollowed)
            : [],
          isSaved,
          isFollowed,
          tags,
          mainTag
        }
      } catch (err) {
        this.$errorHandler(err, this)
        return {}
      }
    },
    async $_refreshDataHandler_getIsFollowedAuthorValue(payload) {
      try {
        const { IsFollowed } = await this.requestAuthor(payload)
        return IsFollowed
      } catch (err) {
        this.$errorHandler(err, this)
        return false
      }
    }
  }
}
