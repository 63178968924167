import apiV2 from '@/utils/apiV2'

export const state = () => ({})

export const getters = {}

export const actions = {
  async requestAboutUs() {
    try {
      const { data } = await this.$axiosV2.get(apiV2.pages.getAboutUs())
      return data
    } catch (err) {
      throw err
    }
  },
  async requestTermsOfUse() {
    try {
      const { data } = await this.$axiosV2.get(apiV2.pages.getTermsOfUse())
      return data
    } catch (err) {
      throw err
    }
  },
  async requestPrivacyNotice() {
    try {
      const { data } = await this.$axiosV2.get(apiV2.pages.getPrivacyNotice())
      return data
    } catch (err) {
      throw err
    }
  }
}

export const mutations = {}
